import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'error',
    loadChildren: () => import('@maplix/error').then((m) => m.ErrorModule),
  },
  {
    path: ':lang',
    loadChildren: () => import('./map-viewer/map-viewer.module').then((m) => m.MapViewerModule),
  },
  { path: '**', redirectTo: 'error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
